import { Button } from "../Parts/Button";
import { Card } from "../Parts/Card";
import { Dialog } from "../Parts/Dialog";
import styles from "./styles.module.scss";

import nemuriCat from "../../../assets/images/common/nemuri_cat.svg";

export type ClientCertificateVerifyErrorDialogProps = {
  onClose: () => void;
};

export const ClientCertificateVerifyErrorDialog: React.FC<
  ClientCertificateVerifyErrorDialogProps
> = ({ onClose }) => {
  return (
    <Dialog>
      <Card isBottomPadding="none">
        <div className={styles.bodyInner}>
          <p className={styles.title}>
            {"\u{1F647}\u{1F3FB}\u{200D}\u{2640}\u{FE0F}"}
            技術的な問題が発生しており
            <br />
            現在検証できません
          </p>
          <div className={styles.note}>
            <p className={styles.noteTop}>
              しばらく時間をおいて、
              <span className={styles.noteBold}>必ず端末を再起動してから</span>
            </p>
            再度お試しください。
          </div>
          <div className={styles.buttonContainer}>
            <img
              className={styles.catImage}
              src={nemuriCat}
              alt="ChirattoCat"
            />
            <Button onClick={onClose} theme="primary" minWidth="120px">
              閉じる
            </Button>
          </div>
        </div>
      </Card>
    </Dialog>
  );
};
